import { IGroupsListContext } from './IGroupsListContext';

export const defaultGroupsListContext: IGroupsListContext = {
  createGroupPolicy: undefined,
  groupNameQuery: '',
  groupSectionUrl: '',
  groupUrls: {},
  groups: [],
  groupsSortBy: undefined,
  isGroupCreating: false,
  navigatingToGroup: '',
  updateProgress: undefined,
};
