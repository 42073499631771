import { useContext } from 'react';
import { UserContext } from './UserContext';

export function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('`useUser()` must be used within a `UserContextProvider`');
  }

  return context;
}
