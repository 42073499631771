import { IGroupsStylesParams, groupsStylesParams } from 'settings/groups';
import { IFeedStylesParams, feedStylesParams } from 'settings/feed';
import { ICommentsStylesParams, commentsStylesParams } from 'settings/comments';
import {
  IAppearanceStylesParams,
  appearanceStylesParams,
} from 'settings/appearance';

export interface IGroupsPageStyles
  extends IGroupsStylesParams,
    IFeedStylesParams,
    ICommentsStylesParams {}

const stylesParams: IGroupsPageStyles = {
  ...appearanceStylesParams,
  ...groupsStylesParams,
  ...feedStylesParams,
  ...commentsStylesParams,
};

export default stylesParams;
