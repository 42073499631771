import { MembersLabelTranslations } from '../../utils/groupMembersLabel';

export const MembersLabelDict: MembersLabelTranslations = {
  Members: 'groups-web.settings.TextTab.members.text',
  Students: 'groups-web.settings.TextTab.students.text',
  Coworkers: 'groups-web.settings.TextTab.coworkers.text',
  Friends: 'groups-web.settings.TextTab.friends.text',
  Gamers: 'groups-web.settings.TextTab.gamers.text',
  Custom: 'groups-web.settings.TextTab.custom.text',
};

export const MembersLabelWithCountDict: MembersLabelTranslations = {
  Members: 'groups-web.member_icu',
  Students: 'groups-web.members.student_icu',
  Coworkers: 'groups-web.members.coworker_icu',
  Friends: 'groups-web.members.friend_icu',
  Gamers: 'groups-web.members.gamer_icu',
  Custom: 'groups-web.members.custom_icu',
};
