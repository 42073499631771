import React from 'react';

import { classes } from './MarginDivider.st.css';

export interface MarginDividerProps {
  marginTop?: number;
  marginBottom?: number;
}

const defaultProps = {
  marginTop: 8,
  marginBottom: 8,
};

export const MarginDivider: React.FC<MarginDividerProps> = ({
  marginTop,
  marginBottom,
}) => {
  const style = { marginTop, marginBottom };

  return <div className={classes.root} style={style} />;
};

MarginDivider.defaultProps = defaultProps;
