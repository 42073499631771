import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { MembersLabelWithCountDict } from '../../MembersLabel/MembersLabel';
import { ButtonPriority } from 'wix-ui-tpa';
import { IGroup } from '../../../types/IGroup';
import { RelationshipWithGroup } from '@wix/ambassador-social-groups-v1-group/types';

export const CTA_BUTTON_PREFERENCES: {
  [key in RelationshipWithGroup]: {
    buttonLabelKey: string;
    priority: ButtonPriority;
  };
} = {
  [RelationshipWithGroup.NONE]: {
    buttonLabelKey: 'groups-web.btn.join-group',
    priority: ButtonPriority.primary,
  },
  [RelationshipWithGroup.PENDING_APPROVAL]: {
    buttonLabelKey: 'groups.web.btn.join-requested',
    priority: ButtonPriority.secondary,
  },
  [RelationshipWithGroup.JOINED]: {
    buttonLabelKey: 'groups-web.btn.view-group',
    priority: ButtonPriority.secondary,
  },
  [RelationshipWithGroup.REJECTED_MEMBERSHIP]: {
    buttonLabelKey: 'groups-web.btn.join-group',
    priority: ButtonPriority.primary,
  },
};

export interface IGroupLabels {
  membersCount: any;
  groupTypeLabel: any;
  pendingMembers: any;
  ctaLabel: any;
  ctaPriority: ButtonPriority;
}

export const useGroupLabels = ({
  membersName,
  privacyLevel,
  memberCount,
  pendingMembersCount,
  relationship,
}: Partial<IGroup>) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const ctaPreferences = CTA_BUTTON_PREFERENCES[relationship!];
  const ctaLabel = t(ctaPreferences.buttonLabelKey);
  const ctaPriority = ctaPreferences.priority;
  const translationKey = membersName
    ? MembersLabelWithCountDict[membersName]
    : MembersLabelWithCountDict.Members;

  const membersCount = translationKey
    ? t(translationKey, {
        count: memberCount,
      })
    : `${memberCount} ${membersName}`;

  const groupTypeLabel = t(
    `groups-web.group-list.privacy.${privacyLevel!.toLowerCase()}`,
  );

  const pendingMembers = t('groups-web.group-list.pending-members.count_icu', {
    count: pendingMembersCount,
  });

  return {
    membersCount,
    groupTypeLabel,
    pendingMembers,
    ctaLabel,
    ctaPriority,
  } as IGroupLabels;
};
