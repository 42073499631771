import {
  MembershipQuestionAnswer,
  MembershipQuestion,
} from '@wix/ambassador-social-groups-v2-membership-question/types';
import type {
  Question as ApiTypesV1Question,
  QuestionIdAnswer as ApiTypesV1QuestionIdAnswer,
} from '@wix/ambassador-social-groups-v1-group-member/types';

export function answerToV2({
  questionId,
  textAnswer,
}: ApiTypesV1QuestionIdAnswer): MembershipQuestionAnswer {
  return { id: questionId!, text: textAnswer! };
}

export function questionToV1({
  id,
  text,
  required,
}: MembershipQuestion): ApiTypesV1Question {
  return { required, questionId: id ?? undefined, textQuestion: text };
}
