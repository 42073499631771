import React from 'react';
import { ModalV2 } from '../../Modal';
import { DialogProps } from 'wix-ui-tpa';
import { Event as IEvent } from '@wix/ambassador-events-v1-event/types';
import { Event } from '../../Event/Event';

interface Props extends DialogProps {
  events: IEvent[];
}

export const RestrictedListModal: React.FC<Props> = ({
  isOpen,
  onClose,
  events,
}) => {
  return (
    <ModalV2 isOpen={isOpen} onClose={onClose}>
      <ModalV2.Title>Register to Join</ModalV2.Title>
      <ModalV2.Content>
        {events.map((ev, i) => {
          return (
            <Event
              key={ev.id}
              event={ev}
              last={i === events.length - 1}
              groupId="groupId"
            />
          );
        })}
      </ModalV2.Content>
    </ModalV2>
  );
};

RestrictedListModal.displayName = 'RestrictedListModal';
