import { useContext } from 'react';
import GroupsContext from './GroupsContext';

export function useGroupsList() {
  const context = useContext(GroupsContext);

  if (!context) {
    throw new Error('`useGroupsList()` must be used within a `GroupsProvider`');
  }

  return context;
}
