import React from 'react';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { isUndefinedOrTrue } from '../../utils/utils';
import settingsParams from 'Groups/settingsParams';
import stylesParams from 'Groups/stylesParams';
import { groupsListWidgetSettingsParams as widgetSettingsParams } from 'settings/groups-list-widget';
import sideBySideSettings from '../../../components/SideBySide/settingsParams';
import sideBySideStyles from '../../../components/SideBySide/stylesParams';
import { IGroupCardSettings } from '../../components/GroupCard/v2/IGroupCardSettings';
import { ImageRatio, LayoutType } from 'settings/consts';
import { WidgetContext } from '../../context/widget/WidgetContext';
import { useImageRatio } from './useImageRatio';
import { WidgetType } from '../../context/widget/WidgetType';

export const useGroupsSettings = () => {
  const settings = useSettings();
  const styles = useStyles();
  const { type } = React.useContext(WidgetContext);

  const showButton = isUndefinedOrTrue(
    settings.get(widgetSettingsParams.showButton),
  );
  const showMembersCount = settings.get(settingsParams.showMemberCount);
  const showGroupType = isUndefinedOrTrue(
    settings.get(widgetSettingsParams.showGroupType),
  );
  const showAdminBadge = settings.get(settingsParams.showAdminBadge);
  const gridCardSpacing = settings.get(settingsParams.gridCardSpacing);
  const showGroupsToJoin = settings.get(settingsParams.showGroupsToJoin);

  const rawMobileGroupCardListSpacing = styles.get(
    stylesParams.groupCardListSpacing,
  );

  const mobileGroupCardListSpacing =
    rawMobileGroupCardListSpacing == null ? 0 : rawMobileGroupCardListSpacing;

  const sideBySideCardSpacing = styles.get(
    sideBySideStyles.sideBySideCardSpacing,
  );

  let groupListLayout: LayoutType;
  // TODO: think of a better pattern
  switch (type) {
    case WidgetType.GroupsPage:
      groupListLayout = settings.get(settingsParams.groupListLayout);
      break;
    case WidgetType.ListWidget:
      groupListLayout = settings.get(widgetSettingsParams.groupListLayout);
      break;
    case WidgetType.SideBySide:
      groupListLayout = settings.get(sideBySideSettings.groupListLayout);
      break;
  }

  const imageRatio: ImageRatio = useImageRatio(groupListLayout!)!;
  let cardHeight: number | undefined;
  if (groupListLayout! === LayoutType.sideBySide) {
    cardHeight = styles.get(stylesParams.cardSideBySideHeight);
  }

  const showImage = settings.get(settingsParams.showImage);
  const listCardSpacing = settings.get(settingsParams.listCardSpacing);

  const imageCrop = settings.get(widgetSettingsParams.imageCrop);
  const imageLayout = settings.get(widgetSettingsParams.imageLayout);
  const textAlignment = settings.get(widgetSettingsParams.textAlignment);
  const textSpacing = settings.get(widgetSettingsParams.textSpacing);

  return {
    showMembersCount,
    showGroupType,
    showAdminBadge,
    showButton,
    gridCardSpacing,
    mobileGroupCardListSpacing,
    groupListLayout: groupListLayout!,
    showImage,
    imageRatio,
    imageCrop,
    imageLayout,
    textAlignment,
    textSpacing,
    listCardSpacing,
    cardHeight,
    sideBySideCardSpacing,
    showGroupsToJoin,
  } as IGroupCardSettings;
};
