import { useContext } from 'react';
import { GroupActionsContext } from './GroupsActionsContext';

export function useGroupsActions() {
  const context = useContext(GroupActionsContext);

  if (!context) {
    throw new Error(
      '`useGroupsActions()` must be used within a `GroupActionsProvider`',
    );
  }

  return context;
}
