import React from 'react';
import classnames from 'classnames';

import { LayoutType } from 'settings/consts';
import { UISref } from 'common/router';

import { GROUP_TITLE_LINK } from '../meta/dataHooks';
import { RoleBadges } from '../../RoleBadges';
import { IGroup } from '../../../types/IGroup';
import { IDetailsSettings } from './IGroupCardSettings';
import { IGroupLabels } from './useGroupLabels';

import {
  classes as badgesClasses,
  st as badgesSt,
} from '../../RoleBadges/GroupsListBadge.st.css';
import { classes, st } from './Details.st.css';

interface Props {
  className?: string;
  mobile: boolean;
  groupUrl: string;
  groupListLayout: LayoutType;
  labels: Partial<IGroupLabels>;
  group: IGroup;
  settings: IDetailsSettings;
}

export const Details: React.FC<Props> = ({
  className,

  mobile,
  groupUrl,
  groupListLayout,
  group,
  settings,
  labels,
}) => {
  const { pendingMembersCount, relationship, roles, title } = group;
  const { showMembersCount, showAdminBadge, showGroupType, textAlignment } =
    settings;
  const { membersCount, groupTypeLabel, pendingMembers } = labels;
  const _textAlignment =
    groupListLayout === LayoutType.sideBySide ? textAlignment : undefined;

  return (
    <div
      className={st(
        classes.root,
        {
          mobile,
          layout: groupListLayout,
          textAlignment: _textAlignment,
        } as any,
        classnames(classes.meta, className),
      )}
    >
      <div className={classes.header}>
        <a
          data-hook={GROUP_TITLE_LINK}
          className={classes.link}
          href={groupUrl}
          data-bi-origin="group_title"
        >
          {title}
        </a>
        {showAdminBadge && (
          <RoleBadges
            relationship={relationship!}
            roles={roles!}
            getBadgeClassName={(isAdmin) =>
              badgesSt(badgesClasses.badge, { isAdmin })
            }
          />
        )}
      </div>
      <p className={classes.details}>
        {showGroupType && groupTypeLabel}
        {showMembersCount && showGroupType && <span>&nbsp;&middot;&nbsp;</span>}
        {showMembersCount && (
          <span data-hook="members-count">{membersCount}</span>
        )}
        {showMembersCount && pendingMembersCount ? (
          <span>
            &nbsp;&middot;&nbsp;
            <UISref
              state="group.members"
              params={{ slug: group.slug, expandJoinedRequests: true }}
            >
              <a className={classes.pendingMembers} onClick={handleLinkClick}>
                {pendingMembers}
              </a>
            </UISref>
          </span>
        ) : null}
      </p>
    </div>
  );

  function handleLinkClick(e: React.MouseEvent) {
    e.stopPropagation();
  }
};

Details.displayName = 'Details';
