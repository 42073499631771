import React from 'react';
import { IUserContext, UserStatus } from './IUserContext';
import { ProviderProps, getContextFromProps } from '@wix/social-groups-api';

export const defaultUserContext: IUserContext = {
  isSiteAdmin: false,
  myMember: null,
  userRequestResponse: null,
  userPermissions: undefined as any,
  userActions: undefined as any,
  userStatus: UserStatus.UNKNOWN,
  changeMembershipOrigin: undefined,
};

export const UserContext =
  React.createContext<IUserContext>(defaultUserContext);

export const UserContextProvider: React.FC<ProviderProps<IUserContext>> = ({
  children,
  value,
}) => {
  const _value = getContextFromProps(value, defaultUserContext);
  return <UserContext.Provider value={_value}>{children}</UserContext.Provider>;
};

UserContextProvider.displayName = 'UserContextProvider';
