import React from 'react';
import { GroupsConsumer } from './GroupsContext';
import { IGroupsListContext } from '../../../common/context/groups-list/IGroupsListContext';

export const withGroupsListContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) =>
  class WithGroups extends React.Component<P & IGroupsListContext> {
    render() {
      return (
        <GroupsConsumer>
          {(data) => <WrappedComponent {...data} {...this.props} />}
        </GroupsConsumer>
      );
    }
  };

export default withGroupsListContext;
