import React from 'react';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { Dialog, DialogProps } from '../Dialog';
import { DATA_HOOKS } from './dataHooks';
import { memberTabAdminActionDone } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from 'common/bi-logger/types';

interface PrivateProfileDisclaimerProps extends DialogProps {
  onConfirm(): void;
}

export const PrivateProfileDisclaimer: React.FC<
  PrivateProfileDisclaimerProps
> = (props) => {
  const { onConfirm, onClose, ...rest } = props;
  const { t } = useTranslation();
  const bi = useBi();

  return (
    <Dialog
      data-hook={DATA_HOOKS.root}
      title={t('groups-web.private-profile-disclaimer.title')}
      caption={t('groups-web.private-profile-disclaimer.subtitle')}
      okLabel={t('groups-web.private-profile-disclaimer.ok')}
      cancelLabel={t('groups-web.cancel')}
      onOkClick={handleOkClick}
      onCancelClick={handleCancelClick}
      onClose={handleCloseClick}
      {...rest}
    />
  );

  function handleOkClick() {
    reportClick('confirm');
    onConfirm();
  }

  function handleCancelClick() {
    reportClick('cancel');
    props.onCancelClick?.() || props.onClose?.();
  }

  function handleCloseClick() {
    reportClick('close');
    props.onClose?.();
  }

  function reportClick(action: string) {
    bi.report(
      memberTabAdminActionDone({
        action,
        origin: 'private_profile_in_groups',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }
};

PrivateProfileDisclaimer.displayName = 'ProfilePrivacyDialog';
