import { groupsListWidgetSettingsParams as widgetSettingsParams } from 'settings/groups-list-widget';
import { LayoutType } from 'settings/consts';

export default {
  ...(widgetSettingsParams as any),
  groupListLayout: {
    getDefaultValue: () => LayoutType.sideBySide,
    key: 'groupListLayout',
  },
};
