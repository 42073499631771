import React from 'react';
import { DialogProps, Dialog } from '../../Dialog';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useUser } from '../../../context/user/useUser';
import { getLocaleDateString } from '../../../utils/date';
import { usePaidPlansBi } from 'common/hooks/usePaidPlansBi';

type Props = DialogProps;

export const FuturePlansDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const bi = usePaidPlansBi();
  const { userActions, userRequestResponse } = useUser();
  if (!userRequestResponse?.futurePlans?.length) {
    return null;
  }
  const [plan] = userRequestResponse?.futurePlans!;

  const date = getLocaleDateString(new Date(plan.startsAt));

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('groups-web.restriction.plans.future.dialog.title')}
      caption={t('groups-web.restriction.plans.future.dialog.subtitle', {
        date,
      })}
      okLabel={t('groups-web.restriction.plans.future.dialog.cta')}
      cancelLabel={t('groups-web.restriction.plans.future.dialog.cancel')}
      onOkClick={() => {
        bi.getAnotherPlanClick();
        userActions.pickPricingPlan(userRequestResponse!);
      }}
      onCancelClick={onClose}
    />
  );
};

FuturePlansDialog.displayName = 'FuturePlansDialog';
