import { useBi } from '@wix/yoshi-flow-editor';
import { groupsCreateGroupClick } from '@wix/bi-logger-groups/v2';

export function useGroupsBi() {
  const bi = useBi();

  return {
    groupsCreateGroupClick(origin: string) {
      bi.report(
        groupsCreateGroupClick({
          origin,
        }),
      );
    },
  };
}
