import React from 'react';
import { ModalProps, ModalV2 } from '../../Modal';
import { EventsRestricted } from './EventsRestricted';
import { st, classes } from './RegisterEventsDialog.st.css';

type Props = ModalProps;

export const RegisterEventsDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <ModalV2 isOpen={isOpen} onClose={onClose}>
      <ModalV2.Content className={st(classes.root)}>
        <EventsRestricted />
      </ModalV2.Content>
    </ModalV2>
  );
};

RegisterEventsDialog.displayName = 'RegisterEventsDialog';
