import React from 'react';

import {
  ImageLoadingBehaviorOptions,
  WowImage,
  ImageResizeOptions,
  WowImageProps,
} from 'wix-ui-tpa';

import { classes } from './GroupCardMedia.st.css';

export interface GroupCardMediaProps extends WowImageProps {
  url?: string;
  groupUrl: string;
  goToGroup?(event?: any): void;
  resize?: ImageResizeOptions;
}
const noop = () => {};

export const GroupCardMedia: React.FC<GroupCardMediaProps> = ({
  aspectRatio,
  url,
  goToGroup,
  groupUrl,
  ...rest
}) => {
  return (
    <a
      tabIndex={-1}
      href={groupUrl}
      onClick={goToGroup}
      data-hook="group-card-media"
      data-bi-origin="group_image"
      className={classes.root}
    >
      {url && (
        <WowImage
          fluid
          src={url}
          aspectRatio={aspectRatio}
          loadingBehavior={ImageLoadingBehaviorOptions.blur}
          {...rest}
        />
      )}
    </a>
  );
};

GroupCardMedia.displayName = 'GroupCardMedia';
GroupCardMedia.defaultProps = {
  goToGroup: noop,
};
