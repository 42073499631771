import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import { GroupsWidgetWithStyles } from '../../Groups/Widget/App/types';
import { WidgetRoot } from '../../GroupsListWidget/Widget/WidgetRoot/WidgetRoot';
import { WidgetContext } from '../../../common/context/widget/WidgetContext';
import { WidgetType } from '../../../common/context/widget/WidgetType';

const Widget: FC<WidgetProps<GroupsWidgetWithStyles>> = (props) => {
  return (
    <div data-hook="SideBySide-wrapper">
      <WidgetContext.Provider value={{ type: WidgetType.SideBySide }}>
        <WidgetRoot {...props} />
      </WidgetContext.Provider>
    </div>
  );
};

export default Widget;
