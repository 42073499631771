import React from 'react';
import { Button } from '../../Button';
import { ButtonProps } from 'wix-ui-tpa';
import { CTA_BUTTON } from './dataHooks';

interface Props extends ButtonProps {
  className?: string;
  label: string;
}

export const CTA: React.FC<Props> = ({ className, label, ...btnProps }) => {
  return (
    <div className={className}>
      <Button data-hook={CTA_BUTTON} fullWidth {...btnProps}>
        {label}
      </Button>
    </div>
  );
};

CTA.displayName = 'CTA';
