import React from 'react';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';
import { IGroupsListContext } from '../../../common/context/groups-list/IGroupsListContext';
import { defaultGroupsListContext } from '../../../common/context/groups-list/defaultGroupsListContext';
import { getContextFromProps, ProviderProps } from '@wix/social-groups-api';

export const GroupsContext = React.createContext<IGroupsListContext>({
  ...defaultGroupsListContext,
  updateProgress: UpdateProgress.UPDATING,
});
GroupsContext.displayName = 'GroupsContext';

export const GroupsConsumer = GroupsContext.Consumer;
export default GroupsContext;

export const GroupsProvider: React.FC<ProviderProps<IGroupsListContext>> = ({
  children,
  value,
}) => {
  const _value = getContextFromProps(value, defaultGroupsListContext);
  return (
    <GroupsContext.Provider value={_value}>{children}</GroupsContext.Provider>
  );
};
