import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { st, classes } from './GroupList.st.css';

interface NoGroupsProps {
  emptyStateHeader: string;
  emptyStateText: string;
  actionButton?: JSX.Element;
  className?: string;
}

export const NoGroups: React.FC<NoGroupsProps> = ({
  emptyStateHeader,
  emptyStateText,
  actionButton,
  className,
}) => {
  return (
    <div className={st(classes.emptyRoot, {}, className)}>
      <Text
        typography={TextTypography.smallTitle}
        className={classes.emptyStateHeader}
      >
        {emptyStateHeader}
      </Text>
      <Text
        typography={TextTypography.runningText}
        className={classes.emptyStateText}
      >
        {emptyStateText}
      </Text>
      {actionButton}
    </div>
  );
};
