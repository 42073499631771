import React from 'react';
import { AppRootActions } from '../Widget/App/types';

export const GroupActionsContext = React.createContext<AppRootActions>(
  null as any,
);
GroupActionsContext.displayName = 'GroupActionsContext';

export const GroupsActionsProvider = GroupActionsContext.Provider;
export const GroupsActionsConsumer = GroupActionsContext.Consumer;
