import { useContext } from 'react';
import { AppToastsContext } from './AppToastsContext';

export function useAppToasts() {
  const context = useContext(AppToastsContext);

  if (!context) {
    throw new Error(
      '`useAppToasts()` must be used within a `AppToastsProvider`',
    );
  }

  return context;
}
